import React, { ReactNode } from 'react';

import { Head } from '@inertiajs/react';
import Logo from '@/Components/Logo/Logo';

type AuthLayoutProps = {
  children: ReactNode;
  title: string;
};

const AuthLayout: React.FC<AuthLayoutProps> = (props) => {
  return (
    <>
      <Head title={props.title} />

      <div className="h-screen w-full flex justify-center py-15">
        <div className="w-full max-w-70 mx-auto px-4">
          <div className="flex justify-center w-full">
            <Logo width={75} />
          </div>
          <div>{props.children}</div>
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
